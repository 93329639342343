/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const BigShouldersDisplay_100Thin = require('./BigShouldersDisplay_100Thin.ttf');
export const BigShouldersDisplay_200ExtraLight = require('./BigShouldersDisplay_200ExtraLight.ttf');
export const BigShouldersDisplay_300Light = require('./BigShouldersDisplay_300Light.ttf');
export const BigShouldersDisplay_400Regular = require('./BigShouldersDisplay_400Regular.ttf');
export const BigShouldersDisplay_500Medium = require('./BigShouldersDisplay_500Medium.ttf');
export const BigShouldersDisplay_600SemiBold = require('./BigShouldersDisplay_600SemiBold.ttf');
export const BigShouldersDisplay_700Bold = require('./BigShouldersDisplay_700Bold.ttf');
export const BigShouldersDisplay_800ExtraBold = require('./BigShouldersDisplay_800ExtraBold.ttf');
export const BigShouldersDisplay_900Black = require('./BigShouldersDisplay_900Black.ttf');
